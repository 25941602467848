import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import timeac from "../assets/img/timeac.jpg";
import egamelogo from "../assets/img/egame-logo.PNG";
import colorSharp2 from "../assets/img/color-sharp2.png";
import yourproject from "../assets/img/yourproject.png";
import behruzedai from "../assets/img/image.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Time Academy",
      description: "Web sayt",
      imgUrl: timeac,
    },
  ];

  const projects2024 = [
    {
      title: "E Game platformasi",
      description: "Kibersport turnirlarini avtomatlashtiruvchi tizim",
      imgUrl: egamelogo,
    },
    {
      title: "Behruzed AI",
      description: "Turli xil tarmoqlarga moslasha oladigan sun'iy intellekt",
      imgUrl: behruzedai,
    },
    {
      title: "Bu yerda sizning loyihangiz bo`lishi mumkin edi",
      description: "behruzed.uz",
      imgUrl: yourproject,
    },
  ];

  return (
    <section className="project" id="xizmatlar">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Xizmatlar</h2>
                  <div>
                    <ul>
                      <li className="p-1">Front-end va back-end development</li>
                      <li className="p-1">
                        Ma'lumotlar bazalari tuzish va ma'lumotlar analiz qilish
                      </li>
                      <li className="p-1">Shaxsiy CRM tizimini yaratish</li>
                      <li className="p-1">SEO optimizatsiyasi</li>
                      <li className="p-1">UX/UI web dizayn</li>
                    </ul>
                  </div>
                  <h3 className="text-center">Tugallangan loyihalar</h3>
                  <br></br>
                  <Tab.Container id="projects-tabs" defaultActiveKey="second">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">2023</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">2024</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">...</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects2024.map((project2024, index) => {
                            return <ProjectCard key={index} {...project2024} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Bu sahifa hozircha bo`sh:)</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
